import React from 'react';
import ScriptTag from 'react-script-tag';
import isEmpty from '../../_utils/isEmpty';
import InfogramNotFound from './InfogramNotFound';

export default function InforgramContent({ onBackHome, selectedMenu }) {
    return (
        <div style={{ width: "100%" }}>
            {
                isEmpty(selectedMenu) ?
                    null
                    :
                    isEmpty(selectedMenu.shareCode) ?
                        <InfogramNotFound
                            onBackHome={onBackHome}
                            selectedMenu={selectedMenu} />
                        :
                        <ScriptTag
                            id={selectedMenu.shareCode}
                            title={selectedMenu.projectCode}
                            src={`https://e.infogram.com/js/dist/embed.js?${selectedMenu.id}`}
                            type="text/javascript"></ScriptTag>
            }

        </div>
    )
}