import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CloudDownload } from '@material-ui/icons';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { IconButton } from '@material-ui/core';
import isEmpty from '../../_utils/isEmpty';
import { LocaleContext } from '../../contexts/localeContext'
import localize from '../../_utils/localize';

function DownloadMenu({ width, selectedMenu }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { locale } = useContext(LocaleContext)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {
                isWidthUp('sm', width) ?
                    <Button aria-controls="simple-menu" variant="outlined" color="secondary" aria-haspopup="true" onClick={handleClick}>
                        {localize('button.download.title', locale)}&nbsp;&nbsp;<CloudDownload />
                    </Button> :
                    <IconButton aria-controls="simple-menu" variant="" color="secondary" aria-haspopup="true" onClick={handleClick}>
                        <CloudDownload />
                    </IconButton>
            }

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} disabled={isEmpty(selectedMenu.url)}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "black" }}
                        href={selectedMenu.url}
                        download={selectedMenu.name}
                    >
                        {localize('button.download.option1.label', locale)}
                    </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "black" }}
                        href={localize('download.complete.file.url', locale)}
                        download={localize('download.complete.file.name', locale)}
                    >
                        {localize('button.download.option2.label', locale)}
                    </a>
                </MenuItem>
            </Menu>
        </div >
    );
}
export default withWidth()(DownloadMenu);