import { GET_HEALTH } from '../constants/types';

const initialState = {
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HEALTH:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}