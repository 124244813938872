import React, { Component } from 'react';
import LocaleContextProvider from './contexts/localeContext'
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import styles from './withAppInitializer.css';
import withStyles from '@material-ui/core/styles/withStyles';
import { checkApiHealth } from './_redux/actions/health';
import { clearErrors } from './_redux/actions/errors';
import logo from './assets/images/logo-white.png';
import { CircularProgress } from '@material-ui/core';
import isEmpty from './_utils/isEmpty';
import isEqual from './_utils/isEqual';
import localize from './_utils/localize';


function LoadingMessage(classes, component) {
    return (
        <div className={classes.splashScreen}>
            <div className={classes.logo}><img src={logo} alt="logo" height="100px" /></div>
            {component}
        </div>
    );
}

function withAppInitializer(WrappedComponent, locale) {

    var AppInitializer = class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true
            };
            this.apiHealthTimeOutHook = null;
            this.props.checkApiHealth();
        }
        async componentDidMount() {
        }

        timeOut = () => {
            const { health } = this.props;
            if (isEmpty(health.data)) {
                this.props.checkApiHealth();
                this.setState({ loading: false });
            } else {
                if (!isEmpty(this.apiHealthTimeOutHook)) {
                    clearTimeout(this.apiHealthTimeOutHook);
                    this.apiHealthTimeOutHook = null;
                }
            }
        }

        componentDidUpdate(prevProps) {
            if (!isEqual(prevProps.health, this.props.health) && this.state.loading) {
                this.setState({ loading: false });
            }
        }

        render() {
            const { loading } = this.state;
            const { classes, health } = this.props;
            this.apiHealthTimeOutHook = setTimeout(this.timeOut, 5000);
            if (!isEmpty(health.data) && health.data.status.toLowerCase() === "up") {
                return (
                    <div>
                        <LocaleContextProvider>
                            <WrappedComponent {...this.props} />
                        </LocaleContextProvider>
                    </div>
                )
            }

            if (loading)
                return LoadingMessage(classes,
                    <div>
                        <CircularProgress className={locale === 'fr' ? classes.loadingCircle_fr : classes.loadingCircle} />
                        <div className={classes.loadingText}>{localize('body.loading.label', locale)}</div>
                    </div>
                )
            else if (isEmpty(health.status))
                return LoadingMessage(classes,
                    <div>
                        <div className={classes.loadingText}>
                            {localize('error.server.title', locale)} <a href=".">{localize('error.try.label', locale)}</a>
                        </div>
                    </div>
                )
            else if (health.status === "Healthy")
                return (
                    <div>
                        <LocaleContextProvider>
                            <WrappedComponent {...this.props} />
                        </LocaleContextProvider>
                    </div>
                )
            else
                return LoadingMessage(classes,
                    <div>
                        <div className={classes.loadingText}>
                            {localize('error.general.title', locale)} <a href=".">{localize('error.try.label', locale)}</a>
                        </div>
                    </div>
                )
        }
    }

    const mapStateToProps = (state) => ({
        health: state.health
    })

    return compose(
        withStyles(styles, { name: 'AppInitializer' }),
        connect(mapStateToProps, { checkApiHealth, clearErrors })
    )(AppInitializer);
}

export default withAppInitializer;