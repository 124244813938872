import React, { useContext } from 'react';
import { makeStyles, fade, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeView from '@material-ui/lab/TreeView';
import { MenuOpen } from '@material-ui/icons';
import DefaultEndIcon from '@material-ui/icons/LabelImportant';
import DefaultCollapseIcon from '@material-ui/icons/IndeterminateCheckBox';
import DefaultExpandIcon from '@material-ui/icons/AddBox';

import isEmpty from '../../_utils/isEmpty';
import SearchIcon from '@material-ui/icons/Search';
import { InputBase, CircularProgress, Typography } from '@material-ui/core';
import { DRAWER_MAX_WIDTH, MAIN_APPBAR_HEIGHT, DASH_APPBAR_HEIGHT } from '../../_utils/constants';
import { auCorporateGreen, auWhite } from '../../auColours';
import StyledTreeItem from '../common/StyledTreeItem';
import { LocaleContext } from '../../contexts/localeContext'
import localize from '../../_utils/localize';
// import { S3_DOMAIN } from '../../_redux/constants/api';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        height: DASH_APPBAR_HEIGHT,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2) !important',
        width: '100%',
        zIndex: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: DRAWER_MAX_WIDTH,
        flexShrink: 0,
    },
    drawerPaper: {
        marginTop: MAIN_APPBAR_HEIGHT,
        width: DRAWER_MAX_WIDTH,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
    },
    sideContent: {
        marginTop: DASH_APPBAR_HEIGHT,
        zIndex: -1,
        marginBottom: 100,
        padding: 15,
    },
    treeView: {
        flexGrow: 1
    },
    flag: {
        fontSize: 20
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 150,
            '&:focus': {
                width: 170,
            },
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: DASH_APPBAR_HEIGHT,
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        borderBottom: `4px solid ${auCorporateGreen}`,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2) !important',
        position: "fixed",
        width: DRAWER_MAX_WIDTH,
        backgroundColor: auWhite,
        [theme.breakpoints.down('xs')]: {
            width: "100%"
        },
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    }
}));

export default function SideDrawer({ open, onDrawerClose, onSelect, menus, breadcrumbs }) {
    const classes = useStyles();
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(breadcrumbs.map(menu => menu.id.toString()));
    const [keyword, setKeyword] = React.useState('');
    const { locale } = useContext(LocaleContext)

    const handleChange = (event) => {
        setKeyword(event.target.value.toLowerCase());
    }

    const getParents = (parentIds) => {
        if (isEmpty(parentIds)) return [];
        let parents = menus.results.filter(menu => parentIds.includes(menu.id));
        let nextParentIds = parents.map(menu => menu.parentId);
        return [...parents, ...getParents(nextParentIds)];
    }

    const handleNodeSelect = (value) => {
        if (expanded.includes(value)) {
            setExpanded(expanded.filter(node => node !== value));
        } else {
            setExpanded([...expanded, value]);
        }
    }

    let finalMenu = [];
    let finalExpanded = [];
    if (!isEmpty(menus) && !isEmpty(keyword) && !isEmpty(keyword)) {
        try {
            var searchResults = menus.results.filter(node => node.name.toLowerCase().includes(keyword.toLowerCase()));
            var parentResults = getParents(searchResults.map(menu => menu.parentId));

            var mergedResults = [...searchResults, ...parentResults];
            finalMenu = mergedResults.filter((value, index, self) => self.indexOf(value) === index);
            finalExpanded = finalMenu.map(menu => menu.id.toString());
        } catch (error) {
        }
    } else if (!isEmpty(menus) && isEmpty(keyword)) {
        finalMenu = [...menus.results];
        finalExpanded = expanded;
    }

    const renderTreeItem = (parentId, items) => {
        if (isEmpty(items)) return;
        var treeItems = items.filter(menu => menu.parentId === parentId).map(menu => (
            <StyledTreeItem
                key={menu.id}
                nodeId={menu.id.toString()}
                label={
                    <div className={classes.labelRoot}>
                        {/* <img src={`${S3_DOMAIN}/${(menu.id + 5)}.png`} alt="icon" height={15} className={classes.labelIcon} />  */}
                        <Typography variant="body1">
                            {menu.name}
                        </Typography>
                    </div>
                }
                onClick={() => onSelect(menu, !isEmpty(menu.projectCode))}
            >
                {renderTreeItem(menu.id, finalMenu)}
            </StyledTreeItem>
        ))
        return treeItems;
    }

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.toolbar}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder={localize('menu.search.label', locale)}
                            onChange={handleChange}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                    <IconButton onClick={onDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <MenuOpen />}
                    </IconButton>
                </div>
                <div className={classes.sideContent}>
                    {
                        isEmpty(menus) ?
                            <CircularProgress />
                            :
                            isEmpty(finalMenu) ?
                                <Typography>{localize('sidebar.available.text', locale)}</Typography>
                                :
                                <TreeView
                                    className={classes.treeView}
                                    defaultCollapseIcon={<DefaultCollapseIcon style={{ fontSize: 25 }} color="action" />}
                                    defaultExpandIcon={<DefaultExpandIcon style={{ fontSize: 25 }} color="primary" />}
                                    defaultEndIcon={<DefaultEndIcon style={{ fontSize: 25 }} color="primary" />}
                                    expanded={finalExpanded}
                                    onNodeSelect={(event, value) => handleNodeSelect(value)}
                                >
                                    {renderTreeItem(0, finalMenu)}
                                </TreeView>
                    }
                </div>
            </Drawer>
        </div>
    );
}