import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import healthReducer from './healthReducer';
import menuReducer from './menuReducer';
import projectReducer from './projectReducer';

const rootReducers = combineReducers({
    errors: errorReducer,
    health: healthReducer,
    menus: menuReducer,
    projects: projectReducer

});

export default rootReducers;