import axios from 'axios';
import { GET_MENU, GET_ERRORS } from '../constants/types';
import { API_BASE_URL, ENDPOINT } from '../constants/api';

export const getMenus = (lang) => dispatch => {
    return axios.get(`${API_BASE_URL}${ENDPOINT.MENU}?limit=200&lang=${lang}`)
        .then(res => {
            res.data.results.sort((a,b) => a.position - b.position);
            dispatch({
                type: GET_MENU,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });
}