import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { DRAWER_MAX_WIDTH, DASH_APPBAR_HEIGHT } from '../../_utils/constants';
import { Breadcrumbs, Button, Tooltip } from '@material-ui/core';
import isEmpty from '../../_utils/isEmpty';
import Menu from '@material-ui/icons/Menu';
import DownloadMenu from '../common/DownloadMenu';
import { auCorporateGreen } from '../../auColours';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2) !important',
        width: '100%',
        zIndex: 2
    },
    appBar: {
        position: 'fixed',
        top: 'auto',
        backgroundColor: theme.palette.common.white,
        borderBottom: `4px solid ${auCorporateGreen}`,
        height: DASH_APPBAR_HEIGHT,
        color: theme.palette.common.black,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: DRAWER_MAX_WIDTH,
        width: `calc(100% - ${DRAWER_MAX_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            marginLeft: "100%",
            width: 0
        },
    },
    menuButton: {
        //marginRight: 15,
    },
    spacer: {
        flexGrow: 1,
        display: 'block'
    },
    hide: {
        display: 'none',
    }
}));

function DashAppBar({ open, onDrawerOpen, onSelect, selectedMenu, breadcrumbs, width }) {
    const classes = useStyles();
    const truncate = (name) => name.length > 30 ? `${name.substring(0, 30)}...` : name;

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <Menu />
                    </IconButton>
                    <div>
                        {
                            isWidthUp('sm', width) ?
                                <Breadcrumbs maxItems={2} aria-label="breadcrumb">
                                    {
                                        !isEmpty(breadcrumbs) &&
                                        breadcrumbs.map(menu => (
                                            <Tooltip title={menu.name} key={menu.id}>
                                                <Button
                                                    color={menu.id === selectedMenu.id ? "secondary" : "primary"}
                                                    onClick={() => onSelect(menu, true)}>{truncate(menu.name)}
                                                </Button>
                                            </Tooltip>
                                        ))
                                    }
                                </Breadcrumbs>
                                :
                                <Tooltip title={selectedMenu.name} key={selectedMenu.id}>
                                    <Button
                                        color="secondary"
                                        onClick={() => onSelect(selectedMenu, true)}>{truncate(selectedMenu.name)}
                                    </Button>
                                </Tooltip>
                        }
                    </div>
                    <div className={classes.spacer} />
                    <DownloadMenu selectedMenu={selectedMenu} />
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default withWidth()(DashAppBar);