import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import logo from '../../assets/images/logo-color.png';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: 50,
        textAlign: 'center',
        padding: 15
    },
    logo: {
        height: 150,
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    }
}));
export default function InfogramNotFound({ onBackHome, selectedMenu }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img src={logo} alt="logo" className={classes.logo} />
            <Typography variant="h2">
                Oopsie!
            </Typography>
            <br />
            <Typography variant="h4">
                {selectedMenu.name} not found.
            </Typography>
            <br />
            <Typography color="secondary">
                Error code: 404
             </Typography>
            <br />
            <Typography variant="h6">
                But don't worry. Here are some helpful links instead:
            </Typography>
            <br />
            <Button color="secondary" variant="outlined" onClick={onBackHome}>
                Back to Africa
            </Button>
        </div>
    )
}