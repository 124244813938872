import React, { createContext, useState } from 'react'
export const LocaleContext = createContext();
const LocaleContextProvider = (props) => {
    const [locale, setLocale] = useState('en');
    return (
         <LocaleContext.Provider 
            value={{
                locale,
                setLocale
             }}>
               {props.children}
         </LocaleContext.Provider>
    );
}
export default LocaleContextProvider;