import React, { useContext } from 'react';
import { LocaleContext } from './contexts/localeContext'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import withAppInitializer from './withAppInitializer';
import queryString from 'query-string';

import Home from './components/pages/Home';
import NotFound from './components/pages/NotFound';
import { auCorporateGreen, auRed, auGold } from './auColours';
import { ALLOWED_LANGUAGES } from './_utils/constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: auCorporateGreen
    },
    secondary: {
      main: auRed
    },
    action: {
      main: auGold
    }
  },
  typography: {
    useNextVariants: true,
    button: {
      textTransform: "none"
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          // backgroundImage: "url(https://img.wallpapersafari.com/desktop/1920/1080/73/1/dXiYMx.jpg)"
        }
      }
    }
  }
});

function getQuery() {
  let params = queryString.parse(window.location.search)

  return params;
}

function getLang() {
  let query = getQuery();
  let lang = query["lang"];
  if (!ALLOWED_LANGUAGES.includes(lang)) {
      lang = 'en';
  }
  return lang;
}

let lang = getLang();

function App() {
  const { setLocale } = useContext(LocaleContext)
  setLocale(lang);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default withAppInitializer(App, lang);
