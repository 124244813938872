import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import styles from './NotFound.css';

class NotFound extends Component {
	render() {
		const { classes } = this.props;
		
		return (
			<div className={classes.root}>

				
				<Typography className={classes.title}>
					Oopsie!
				</Typography>

				<Typography className={classes.message} variant="h1">
					Page not found.
				</Typography>

				<Typography className={classes.error}>
					Error code: 404
				</Typography>

				<div className={classes.links}>
					<Typography>
						But don't worry. Here are some helpful links instead:
					</Typography>

					<Button color="secondary" component={Link} to="/">
						Home
					</Button>
					<br />
					<Button color="secondary" href="https://reactjs.org/" target="_blank">
						Help Centre
					</Button>
				</div>

			</div>
		);
	}
}

export default withStyles(styles)(NotFound);