import { GET_ERRORS, CLEAR_ERRORS } from "../constants/types";
import isEmpty from '../../_utils/isEmpty';
const initialState = {
    messages: '',
    status: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            let payload = !isEmpty(action.payload) ? action.payload : { data: { statusCode: 500, messages: ["Unable to connect to the server."]} };
            return {
                ...state,
                status: payload.data.statusCode,
                messages: payload.data.messages
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                status: '',
                messages: ''
            };
        default:
            return state;
    }
}