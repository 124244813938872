import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import logo from '../../assets/images/logo-full-white.png';
import { MAIN_APPBAR_HEIGHT } from '../../_utils/constants';
import { auRed, auCorporateGreen, auWhite } from '../../auColours';
import { Phone, Email } from '@material-ui/icons';
import { LocaleContext } from '../../contexts/localeContext'
import localize from '../../_utils/localize';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: MAIN_APPBAR_HEIGHT
    },
    rootAppBar: {
        padding: 0
    },
    rootMuiToolbar: {
        display: "flex",
        alignItems: "initial"
    },
    gridItemTop: {
        flex: 1,
        paddingRight: 20,
        paddingLeft: 20,
        backgroundColor: auRed
    },
    gridItemBotton: {
        flex: 2,
        backgroundColor: auCorporateGreen
    },
    news: {
        float: "left",
        alignSelf: "flex-end",
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    link: {
        color: auWhite
    },
    contacts: {
        float: "right",
    },

    btnLogo: {
        float: "left",
        padding: 0
    },
    logo: {
        height: 55,
        marginRight: 15
    },
    title: {
        marginTop: 2,
        float: "left",
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    locale: {
        marginTop: 18,
        marginRight: 20,
        float: "right"
    },
    btnEmblem: {
        float: "right",
        padding: 5,
        marginRight: 20
    },
    emblem: {
        height: 50
    }
}));



export default function MainAppBar({ defaultMenu, onSelect }) {
    const classes = useStyles();
    const { locale } = useContext(LocaleContext)

    function goHome() {
        onSelect(defaultMenu, true)
    }
    return (
        <div className={classes.root}>
            <AppBar position="fixed" classes={{ root: classes.rootAppBar }}>
                <Toolbar disableGutters={true} classes={{ root: classes.rootMuiToolbar }}>
                    <Grid container direction="column">
                        <Grid item xs={12} className={classes.gridItemTop}>
                            <div className={classes.news}>
                                <Typography variant="caption">
                                    {localize('header.covid19.title1', locale)} <b>{localize('header.covid19.title2', locale)}</b> {localize('header.covid19.title3', locale)} <a href="https://au.int/en/covid19" target="_blank" className={classes.link} rel="noopener noreferrer nofollow">www.au.int</a>
                                </Typography>
                            </div>
                            <div className={classes.contacts}>
                                <Phone style={{ fontSize: 14, marginBottom: -2 }} />
                                &nbsp;
                                <Typography variant="caption">(+27) 11 - 256 -3400</Typography>
                                &nbsp;&nbsp;&nbsp;
                                <Email style={{ fontSize: 14, marginBottom: -2 }} />
                                <Typography variant="caption">info@aprm-au.org</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItemBotton}>
                            <Button className={classes.btnLogo} onClick={goHome}>
                                <img src={logo} alt="logo" className={classes.logo} />
                            </Button>

                            <div className={classes.title}>
                                <Typography variant="h5">{localize('header.heading', locale)}</Typography>
                                <Typography variant="caption">{localize('header.subheading', locale)}</Typography>
                            </div>

                            <div className={classes.locale}>
                                <Typography variant="caption">
                                    <a href="/" className={classes.link} rel="noopener noreferrer nofollow">English</a> | <a href="/?lang=fr" className={classes.link} rel="noopener noreferrer nofollow">Français</a>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}