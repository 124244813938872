import React, { Component, Fragment } from 'react';
import localize from '../../_utils/localize';
import clsx from 'clsx';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import styles from './Home.css';
import MainAppBar from '../navigation/MainAppBar';
import DashAppBar from '../navigation/DashAppBar';
import SideDrawer from '../drawers/SideDrawer';
import { ROUTES } from '../../_utils/constants';
import { getMenus } from '../../_redux/actions/menus';
import { CircularProgress, Typography } from '@material-ui/core';
import isEmpty from '../../_utils/isEmpty';
import isEqual from '../../_utils/isEqual';
import InforgramContent from '../common/InforgramContent';
import logo from '../../assets/images/logo-color.png';
import { auCorporateGreen } from '../../auColours';
import { ALLOWED_LANGUAGES } from '../../_utils/constants';
import queryString from 'query-string';

class Home extends Component {
    constructor(props) {
        super(props);
        const lang = this.getLang();
        this.props.getMenus(lang);
        let defaultMenu = this.getHomeMenuItem(lang);
        this.state = {
            loading: false,
            locale: lang,
            overlayLoader: true,
            sideDrawerOpen: isWidthUp('sm', this.props.width),
            defaultMenu: defaultMenu,
            breadcrumbs: [defaultMenu],
            selectedMenu: defaultMenu,
            loadedContent: ''
        }
        this.timer = setTimeout(() => this.setState({ overlayLoader: false }), 1500);
    }

    getHomeMenuItem(lang) {
        let item = {
            id: 153,
            name: "Home",
            projectCode: "Home_2022_EN",
            shareCode: "infogram_0_e12baf1a-f0ab-4f14-8ff6-c282f4888fb8",
        };

        if (lang === 'fr') {
            item = {
                id: 719,
                name: "Accueil",
                projectCode: "Home_2022_FR",
                shareCode: "infogram_0_88b2fca7-93fe-452e-b096-a030fe8c63f7",
            };
        }
        return item;
    }

    getQuery() {
        let params = queryString.parse(window.location.search)
        
        return params;
    }
      
    getLang() {
        let query = this.getQuery();
        let lang = query["lang"];
        if (!ALLOWED_LANGUAGES.includes(lang)) {
            lang = 'en';
        }
        return lang;
    }

    handleDrawerOpen = () => this.setState({ sideDrawerOpen: true });
    handleDrawerClose = () => this.setState({ sideDrawerOpen: false });

    handleSelectMenu = (menu, selected) => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        var newBreadcrumbs = [];
        if (selected) {
            var isMobile = isWidthDown('sm', this.props.width);
            if (!isEmpty(this.state.selectedMenu) && this.state.selectedMenu.id === menu.id) {
                this.setState({ sideDrawerOpen: !isMobile });
            } else if (isEmpty(menu.projectCode)) {
                newBreadcrumbs.push(menu);
                this.getBreadcrumbs(menu, this.props.menus.data.results, newBreadcrumbs);
                this.setState({ breadcrumbs: newBreadcrumbs.reverse() });
            }
            else {
                this.setState({ selectedMenu: menu, loading: true, overlayLoader: true, sideDrawerOpen: !isMobile });
                newBreadcrumbs.push(menu);
                this.getBreadcrumbs(menu, this.props.menus.data.results, newBreadcrumbs);
                this.setState({ breadcrumbs: newBreadcrumbs.reverse() });
                this.timer = setTimeout(() => this.setState({ overlayLoader: false }), 1500)
            }
        }
    }

    handleBackToHome = () => {
        this.handleSelectMenu(this.state.defaultMenu, true);
    }

    getBreadcrumbs = (item, items, newBreadcrumbs) => {
        if (isEmpty(items)) return;
        if (item.parentId > 0) {
            var parentMenu = items.find(x => x.id === item.parentId);
            newBreadcrumbs.push(parentMenu);
            this.getBreadcrumbs(parentMenu, items, newBreadcrumbs);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.selectedMenu, this.state.selectedMenu) && !isEmpty(this.state.selectedMenu)) {
            this.setState({ loading: false });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const { menus, classes } = this.props;
        const { sideDrawerOpen, defaultMenu, selectedMenu, breadcrumbs, loading, overlayLoader } = this.state;
        return (
            <Fragment>
                <MainAppBar
                    currentRoute={ROUTES.root}
                    selectedMenu={selectedMenu}
                    defaultMenu={defaultMenu}
                    onSelect={this.handleSelectMenu}
                />

                <DashAppBar
                    onDrawerOpen={this.handleDrawerOpen}
                    onSelect={this.handleSelectMenu}
                    breadcrumbs={breadcrumbs}
                    selectedMenu={selectedMenu}
                    open={sideDrawerOpen}
                />

                <SideDrawer
                    onDrawerClose={this.handleDrawerClose}
                    onSelect={this.handleSelectMenu}
                    open={sideDrawerOpen}
                    selectedMenu={selectedMenu}
                    breadcrumbs={breadcrumbs}
                    menus={menus.data}
                />

                <main className={clsx(classes.content, {
                    [classes.contentShift]: sideDrawerOpen,
                })}>
                    {
                        overlayLoader &&
                        <div className={clsx(classes.overlayLoader, {
                            [classes.overlayLoaderShift]: sideDrawerOpen,
                        })}>
                            <div className={clsx(classes.overlayLoaderContent, {
                                [classes.overlayLoaderContentShift]: sideDrawerOpen,
                            })}>
                                <div className={classes.logo}><img src={logo} alt="logo" height="100px" /></div>
                                <CircularProgress style={{ color: auCorporateGreen }} />
                                <br />
                                <Typography color="secondary">
                                    {localize('body.loading.title', this.state.locale)}
                                </Typography>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <InforgramContent
                            onBackHome={this.handleBackToHome}
                            selectedMenu={selectedMenu}
                        />
                    }
                </main>
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => ({
    errors: state.errors,
    menus: state.menus
})

export default compose(
    withStyles(styles),
    withRouter,
    withWidth(),
    connect(mapStateToProps, { getMenus })
)(Home);