import { auCorporateGreen, auWhite } from "./auColours";

export default theme => ({
    splashScreen: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: auCorporateGreen
    },
    logo: {
        display: "inline"
    },
    loadingCircle: {
        marginTop: 18,
        marginBottom: 10,
        marginLeft: 12,
        color: auWhite
    },
    loadingCircle_fr: {
        marginTop: 18,
        marginBottom: 10,
        marginLeft: 50,
        color: auWhite
    },
    loadingText: {
        marginTop: 15,
        marginBottom: 15,
        color: auWhite
    }
});