import { DRAWER_MAX_WIDTH, DASH_APPBAR_HEIGHT, MAIN_APPBAR_HEIGHT } from "../../_utils/constants";
import { auWhite } from "../../auColours";

export default theme => ({
  content: {
    position: 'absolute',
    marginTop: DASH_APPBAR_HEIGHT,
    width: "100%",
    //backgroundColor:"blue",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginLeft: DRAWER_MAX_WIDTH,
    width: `calc(100% - ${DRAWER_MAX_WIDTH}px)`,
    //backgroundColor:"blue",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  overlayLoader: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column',
    position: "fixed",
    backgroundColor: auWhite,
    width: "100%",
    height: `calc(100% - ${MAIN_APPBAR_HEIGHT + DASH_APPBAR_HEIGHT}px)`,
    bottom: 0,
    left: 0,
    zIndex: 100
  },
  overlayLoaderShift: {
    marginLeft: DRAWER_MAX_WIDTH,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '200%'
    }
  },
  overlayLoaderContent: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column'
  },
  overlayLoaderContentShift: {
    marginLeft: 0 - DRAWER_MAX_WIDTH,
  },
});