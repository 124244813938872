const messages = {
    en: {
       'menu.search.label': `Search...`,
       'header.covid19.title1': `For`,
       'header.covid19.title2': `COVID-19`,
       'header.covid19.title3': `updates, visit official african union website`,
       'header.heading': `GOVERNANCE ATLAS`,
       'header.subheading': `Africa's Self-Assessment for Good Governance`,
       'body.loading.label': `Loading...`,
       'body.loading.title': `Loading please wait...`,
       'button.download.title': `Download`,
       'button.download.option1.label': `Current section`,
       'button.download.option2.label': `Full document`,
       'error.try.label': `Try again.`,
       'error.server.title': `Unable to connect to the server.`,
       'error.general.title': `Something went wrong.`,
       'sidebar.available.text': `Not available`,
       'download.complete.file.url': `https://au-atlas-pdf.s3.eu-west-2.amazonaws.com/Atlas_2023_EN.pdf`,
       'download.complete.file.name': `Atlas_2023_EN`
    },
    fr: {
       'menu.search.label': `Recherche...`,
       'header.covid19.title1': `Pour les mises à jour du`,
       'header.covid19.title2': `COVID-19`,
       'header.covid19.title3': `, visitez le site officiel de l'Union Africaine`,
       'header.heading': `GOUVERNANCE ATLAS`,
       'header.subheading': `Auto-évaluation de l'Afrique pour la bonne gouvernance`,
       'body.loading.label': `Chargement en cours...`,
       'body.loading.title': `en cours de chargement, veuillez patienter SVP...`,
       'button.download.title': `Télécharger`,
       'button.download.option1.label': `Section actuelle`,
       'button.download.option2.label': `Document complet`,
       'error.try.label': `Réessayer.`,
       'error.server.title': `Impossible de se connecter au serveur.`,
       'error.general.title': `Quelque chose s'est mal passé.`,
       'sidebar.available.text': `Pas disponible`,
       'download.complete.file.url': `https://au-atlas-pdf.s3.eu-west-2.amazonaws.com/Atlas_2023_FR.pdf`,
       'download.complete.file.name': `Atlas_2023_FR`
    }
}

const localize = (label, lang) => {
    return messages[lang][label];
}
export default localize;