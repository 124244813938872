import { GET_PROJECT } from "../constants/types";
const initialState = {
    data: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PROJECT:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}