export default theme => ({
	root: {
		flexGrow: 1,
		marginTop: 50,
		textAlign: 'center',
		fontWeight: 700
	},
	title: {
		marginBottom: 30,
		fontSize: 48,
		fontWeight: 700
	},
	message: {
		marginBottom: 20,
		fontSize: 32,
		fontWeight: 700
	},

	error: {
		fontSize: 18,
		fontWeight: 700,
	},
	links: {
			marginTop: 30,
			fontSize: 18,
			fontWeight: 700,
		'& p': {
			fontSize: 18,
			fontWeight: 700,
		},
		'& a': {
			margin: '20px auto 0 auto',
			fontSize: 16,
			textTransform: 'capitalize',
		},
	},
});