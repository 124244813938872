import Axios from 'axios';
import { GET_HEALTH, GET_ERRORS } from '../constants/types';
import { API_PORTAL, ENDPOINT } from '../constants/api';


export const checkApiHealth = () => dispatch => {
    return Axios.get(`${API_PORTAL}${ENDPOINT.HEALTH}`)
        .then(res => {
            dispatch({
                type: GET_HEALTH,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            });
        });


}