import { applyMiddleware, createStore, compose } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

const inititalState = {};
const middleware = [thunk];

const store = process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined ?
    //development 
    createStore(
        rootReducer,
        inititalState,
        compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()))
    :
    //production 
    createStore(
        rootReducer,
        inititalState,
        compose(applyMiddleware(...middleware)));

export default store;